import React, { useEffect, useState } from 'react';
import './BlogHome.css';
import { getLatestWithType } from '../Index';
import { useNavigate } from 'react-router-dom';

function BlogHome() {
  const [articles, setArticles] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setArticles(getLatestWithType(Number.MAX_SAFE_INTEGER, 1));
  }, [])

  const selectArticle = (url) => {
    navigate("." + url);
  }

  return (
    <div className="blogHome">
      {articles?.map((article, index) =>
        <div className="blogHome__element" key={index}
          onClick={() => { selectArticle(article.url) }}>
          <div className="blogHome__element__image">{article.image}</div>
          <div className="blogHome__element__title">
            <b>{article?.title["en"]}</b>
          </div>
          <div className="blogHome__element__date">{article?.date.toLocaleDateString()}</div>
        </div>
      )}
    </div>
  )
}

export default BlogHome

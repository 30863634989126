import React from 'react'

function Space(props) {
  const height = props.height ? props.height : "32px";
  return (
    <div style={{ height: height, width: "100%" }} />
  )
}

export default Space

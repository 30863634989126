import React from 'react';
import './AboutProject.css';

function AboutProject() {
  return (
    <div className="aboutProject">

    </div>
  )
}

export default AboutProject

import React, { useEffect, useState } from 'react';
import { InstagramEmbed } from 'react-social-media-embed';
import { connect } from 'react-redux';
import { getInstaWidth } from '../Function/HandleWidth';

function InstaPost(props) {
  const [widthPx, setWidthPx] = useState("500px");

  useEffect(() => {
    setWidthPx(getInstaWidth(props.width))
  }, [props.width])

  return (
    <div className="instaPost" style={{ marginTop: "20px", marginBottom: "20px" }}>
      <InstagramEmbed
        url={props.url}
        width={widthPx}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    width: state.width
  };
};

export default connect(mapStateToProps)(InstaPost);
import React, { useState } from 'react';
import './Header.css';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import FadeInOut from './General/FadeInOut';
import { useNavigate } from 'react-router-dom';
import openIcon from './Image/open_sidebar.png';
import clsoeIcon from './Image/close_sidebar.png';

function Header(props) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const menuClicked = (key) => {
    setOpen(false);
    switch (key) {
      case 0:
        navigate('/');
        return;
      case 1:
        navigate('/blog/general');
        return;
      case 2:
        navigate('/blog/tech');
        return;
      default:
        return;
    }
  }

  return (
    <div className="header">
      <div className="header__title">
        <div className="header__title__left">
          {props.extended ?
            <img src={clsoeIcon} className="sidebar__header__image" onClick={() => { props.setExtended(false) }} /> :
            <img src={openIcon} className="sidebar__header__image" onClick={() => { props.setExtended(true) }} />}
        </div>
        <div className="header__title__right">
          <div className="header__title__main">
            <h3>徒然なるままに</h3>
          </div>
          <div className="header__title__sub">
            In idleness...
          </div>
        </div>
      </div>
      <div className="header__menu">
        <FadeInOut className="header__menu__items" show={open} duration={200} >
          <div className="header__menu__item" onClick={() => { menuClicked(0) }}>
            <div className="header__menu__item__title">About</div>
            <div className="header__menu__item__pointer" />
          </div>
          <div className="header__menu__item" onClick={() => { menuClicked(1) }}>
            <div className="header__menu__item__title">Blog</div>
            <div className="header__menu__item__pointer" />
          </div>
          <div className="header__menu__item" onClick={() => { menuClicked(2) }}>
            <div className="header__menu__item__title">Tech</div>
            <div className="header__menu__item__pointer" />
          </div>
        </FadeInOut>
        <div className="header__menu__burger">
          {open ? (<CloseIcon className="header__menu__burger__element"
            onClick={() => { setOpen(false) }} style={{ fontSize: 30 }} />) :
            (<MenuIcon className="header__menu__burger__element"
              onClick={() => { setOpen(true) }} style={{ fontSize: 30 }} />)}
        </div>
      </div>
    </div>
  )
}

export default Header

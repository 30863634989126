import React from 'react'
import '../../../Article.css';

function A2() {
  return (
    <div className="article">
      A2
    </div>
  )
}

export default A2

import React from 'react';
import './Blog.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import Sidebar from './Sidebar/Sidebar';
import LangSwitch from './General/LangSwitch';
import BlogHome from './Blog/BlogHome';
import B1 from './Blog/Articles/B1/B1';
import B2 from './Blog/Articles/B2/B2';
import SideAds from './General/SideAds';

function Blog(props) {

  return (
    <div className="blog">
      <div className="blog__sidebar" extended={(props.extended).toString()} >
        <Sidebar lang={props.lang} setExtended={props.setExtended} extended={props.extended} />
      </div>
      <div className="blog__main" extended={(props.extended).toString()}>
        <div className="blog__body">
          <LangSwitch lang={props.lang} setLang={props.setLang} />
          <Routes >
            <Route path="/" element={<BlogHome />} />
            <Route path="/1" element={<B1 />} />
            <Route path="/2" element={<B2 />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
        <SideAds />
      </div>
    </div>
  )
}

export default Blog

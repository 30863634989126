import React from 'react';
import '../../../Article.css'
import { connect } from 'react-redux';
import InstaPost from '../../../General/InstaPost';
import { Helmet } from "react-helmet";

function B2(props) {
  return (
    <div className="article">
      <Helmet>
        <title>
          {props.lang === "en" ?
            "Climbing in Nyamuk/ Malaysia" :
            "マレーシア，ニャモックで外岩クライミング"}
        </title>
        <meta name="description"
          content={props.lang === "en" ?
            "Small weekend trip to a crag which is really accessible from KL central." :
            "クアラルンプール郊外の外岩へ"} />
      </Helmet>
      <div className="article__header">
        <div className="article__header__main">
          <h1>
            {props.lang === "en" ? (
              <>Climbing in Nyamuk/ Malaysia</>) :
              (<>マレーシア，ニャモックで外岩クライミング</>)}
          </h1>
        </div>
        <div className="article__header__sub">
          {props.lang === "en" ? (
            <>Small weekend trip to a crag which is really accessible from KL central.</>) :
            (<>クアラルンプール郊外の外岩へ</>)}
        </div>
        <div className="article__header__date">
          PUBLISHED JULY 13, 2023
        </div>
      </div>
      <div className="article__body">
        <div className="article__body__intro">
          {props.lang === "en" ? (
            <>In the third week of my Malaysia trip, I went to Nyamuk with friends I met at Camp5.</>) :
            (<>マレーシア滞在の2週目，Camp5で出会った方々とニャモックに行くことにした．</>)}
        </div>
      </div>
      <div className="article__body__paragraph" name="p1">
        <h2>Nyamuk</h2>
        <p>
          {props.lang === "en" ? (
            <>Nyamuk means mosquito in Malay🦟.
              It's not just Nyamuk but Batu Cave area generally has many mosquitos.<br />
              If you are going there, don't forget about it. Or suffer with itchyness like me.<br />
              This crag is in the trees and cooler than other crag because of the shadow but it can be hard to find.
              If you are not comfortable enough, it might be a good idea to go there with locals.<br /></>) :
            (<>ニャモックとはマレー語で蚊という意味であり，
              これはこの場所に限った話ではなくバトゥケイブ地域には蚊がかなり湧いている．<br />
              行く際は虫除けスプレーや蚊取り線香をお忘れなく．<br />
              この場所は木陰で涼しいが少し探しにくいので，地元の人に連れてってもらう方がいいかも知れない．
            </>)}
        </p>
        <InstaPost url="https://www.instagram.com/p/CtseZ9oy39I/" />
      </div>
      <div className="article__body__paragraph" name="p2">
        <h2>
          {props.lang === "en" ? (
            <>How is the routes?</>) :
            (<>ルート感</>)}
        </h2>
        <p>
          {props.lang === "en" ? (
            <>Unlike Damai, there are good hard routes.
              There are many 5.12-13 routes with good height.<br />
              Because Nyamuk is accessible from city, this place probably is the best place to project if you stay in KL.
              However, if you are beginner, it could be hard to find routes although threre are some 5.8/9</>) :
            (<>ここはダマイと違い強度の高い課題が多い．
              高さもクオリティも十分な5.12-13がある．<br />
              クアラルンプール都市部からのアクセスもいいので，絶好のプロジェクト場ではないだろうか．
              5.8/9の課題もあるもののそこまで数がないので，初心者には少し難しい岩場かも知れない．
            </>)}
        </p>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang
  };
};

export default connect(mapStateToProps)(B2);

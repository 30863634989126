const index = {
  1: {
    title: {
      en: "How to make website with React & Firebase",
      ja: "FirebaseとReactでウェブサイトを作ろう"
    },
    date: new Date("6/15/23"),
    tags: [1, 2, 3],
    image: <div style={{ backgroundColor: "var(--yellow)", fontSize: "40px", height: "100%", width: "100%", textAlign: "center" }}>🔥</div>,
    url: "/1",
    type: 0
  },
  2: {
    title: {
      en: "Climbing in Damai/Malaysia",
      ja: "ダマイで外岩登攀 in マレーシア"
    },
    date: new Date("7/6/23"),
    tags: [4],
    image: <div style={{ backgroundColor: "var(--green)", fontSize: "40px", height: "100%", width: "100%", textAlign: "center" }}>🧗🏼‍♂️</div>,
    url: "/1",
    type: 1
  },
  3: {
    title: {
      en: "Climbing in Nyamuk/ Malaysia",
      ja: "マレーシア，ニャモックで外岩クライミング"
    },
    date: new Date("7/13/23"),
    tags: [4],
    image: <div style={{ backgroundColor: "var(--orange)", fontSize: "40px", height: "100%", width: "100%", textAlign: "center" }}>🧗🏼‍♂️</div>,
    url: "/2",
    type: 1
  },
};

export const tags = {
  1: "React",
  2: "Firebase",
  3: "Webapp",
  4: "Climbing"
}

export const getLatestArticle = (n) => {
  const list = [];
  Object.keys(index).forEach((key) => {
    if (key < n) {
      list.push(index[key]);
    }
  });
  list.reverse();
  return list;
}

export const getLatestWithType = (n, type) => {
  const list = [];
  Object.keys(index).forEach((key) => {
    if (key < n && index[key].type === type) {
      list.push(index[key]);
    }
  });
  list.reverse();
  return list;
}

export default index;
import React, { useEffect, useRef } from 'react';
import './App.css';
import About from './Component/About';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Main from './Component/Main';
import useResize from './Component/Hook/Resize';
import { connect } from 'react-redux';
import { setWidth } from './Store/actioncreator';

function App(props) {
  const ref = useRef(null);
  const { width, height } = useResize(ref);

  useEffect(() => {
    props.setWidth(width);
  }, [width])

  return (
    <div className="app" ref={ref}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<About />} />
          <Route path="/blog/*" element={<Main />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    setWidth: (width) => dispatch(setWidth(width)),
  };
};

export default connect(null, mapDispatchToProps)(App);
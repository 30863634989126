import React, { useEffect, useState } from 'react';
import './Home.css';
import { useNavigate } from 'react-router-dom';
import { getLatestArticle } from './Index';
import LangSwitch from './General/LangSwitch';
import { connect } from 'react-redux';

function Home(props) {
  const [articles, setArticles] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setArticles(getLatestArticle(Number.MAX_SAFE_INTEGER, 0));
  }, [])

  const selectArticle = (url, type) => {
    if (type === 0) {
      navigate("./tech" + url);
    } else {
      navigate("./general" + url);
    }
  }

  return (
    <div className="home">
      <div className="home__lang">
        <LangSwitch lang={props.lang} setLang={props.setLang} />
      </div>
      {articles?.map((article, index) =>
        <div className="home__element" key={index}
          onClick={() => { selectArticle(article.url, article.type) }}>
          <div className="home__element__image">{article.image}</div>
          <div className="home__element__title">
            <b>{article?.title[props.lang]}</b>
          </div>
          <div className="home__element__date">{article?.date.toLocaleDateString()}</div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang
  };
};

export default connect(mapStateToProps)(Home);

import { SET_LANG, RESET_LANG, SET_WIDTH, RESET_WIDTH } from "./actiontype";

export const initialState = {
  lang: "en",
  width: 500
}

const reducer = (state = initialState, action) => {
  if (action.type === SET_LANG) {
    state = {
      ...state,
      lang: action.payload.lang
    };
    return state;
  }
  else if (action.type === RESET_LANG) {
    state = {
      ...state,
      lang: "en"
    };
    return state;
  }
  else if (action.type === SET_WIDTH) {
    state = {
      ...state,
      width: action.payload.width
    };
    return state;
  }
  else if (action.type === RESET_WIDTH) {
    state = {
      ...state,
      width: action.payload.width
    };
    return state;
  }
  else {
    return state;
  }
}

export default reducer;
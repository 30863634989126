import React from 'react';
import './Tech.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import A1 from './Tech/Articles/A1/A1';
import A2 from './Tech/Articles/A2/A2';
import Sidebar from './Sidebar/Sidebar';
import LangSwitch from './General/LangSwitch';
import TechHome from './Tech/TechHome';

function Tech(props) {
  return (
    <div className="tech">
      <div className="tech__sidebar" extended={(props.extended).toString()} >
        <Sidebar lang={props.lang} setExtended={props.setExtended} extended={props.extended} />
      </div>
      <div className="tech__main" extended={(props.extended).toString()}>
        <LangSwitch lang={props.lang} setLang={props.setLang} />
        <Routes>
          <Route path="/" element={<TechHome lang={props.lang} />} />
          <Route path="/1" element={<A1 />} />
          <Route path="/2" element={<A2 />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </div>
  )
}

export default Tech

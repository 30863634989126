import React, { useState } from 'react';
import './About.css'
import AboutMenu from './About/AboutMenu';
import AboutHome from './About/AboutHome';
import AboutProject from './About/AboutProject';
import AboutInfo from './About/AboutInfo';
import FadeInOut from './General/FadeInOut';

function About() {
  const [page, setPage] = useState(1);
  const style = { width: "100%", height: "100%" }

  return (
    <div className="about">
      <div className="about__top">
        <div className="about__top__image">
          <div className="ant" />
        </div>
      </div>
      <div className="about__container">
        <div className="about__left">
          <div className="about__left__header">
            <div className="about__left__header__name">
              Yuichi Hamamoto
            </div>
            <div className="about__left__header__title">
              Software Engineer
            </div>
          </div>
          <div className="about__left__menu">
            <AboutMenu page={page} setPage={setPage} />
          </div>
        </div>
        <div className="about__right">
          <FadeInOut show={page === 1} duration={500} style={style}><AboutHome /></FadeInOut>
          <FadeInOut show={page === 2} duration={500} style={style}><AboutInfo /></FadeInOut>
          <FadeInOut show={page === 3} duration={500} style={style}><AboutProject /></FadeInOut>
        </div>
      </div>
    </div>
  )
}

export default About
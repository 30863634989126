import React, { useEffect } from 'react';

function SideAds() {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
    catch (e) {
    }
  }, []);


  return (
    <div style={{ width: "200px" }}>
      <ins className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-3095218573562551"
        data-ad-slot="4827581696"
        data-ad-format="auto"
        data-full-width-responsive="true">
      </ins>
    </div>
  )
}

export default SideAds

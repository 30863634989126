import { useEffect, useState } from 'react';
const useResize = (ref) => {
  const [width, setWidth] = useState(ref.current ? ref.current.offsetWidth : 0)
  const [height, setHeight] = useState(ref.current ? ref.current.offsetWidth : 0)

  useEffect(() => {
    const getDimensions = () => ({
      width: ref.current.offsetWidth,
      height: ref.current.offsetHeight
    })

    const handleResize = () => {
      const d = getDimensions();
      setHeight(d.height);
      setWidth(d.width);
    }

    if (ref.current) {
      const d = getDimensions();
      setHeight(d.height);
      setWidth(d.width);
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [ref])
  return { width, height }
}
export default useResize;
import React from 'react';
import './AboutHome.css'

function AboutHome() {
  return (
    <div className="aboutHome">
      <div className="aboutHome__text">
        <b>Born in 1999 in Japan.</b><br />
        <b>A geek into traveling the world and rock climbing.</b><br />
        <b>I believe software can be more available and user-friendly.</b><br />
        <b>To present the possibilities of software, I am pursuing the new technology from variety of experiences and thoughts.</b><br />
      </div>
    </div>
  )
}

export default AboutHome
import React from 'react';
import './LangSwitch.css';
import { connect } from 'react-redux';
import { setLang } from '../../Store/actioncreator';

function LangSwitch(props) {
  return (
    <div className="langSwitch">
      <div className="langSwitch__box">
        <div className="langSwitch__element" active={(props.lang === "ja")?.toString()}
          onClick={() => { props.setLang("ja") }}>
          🇯🇵
        </div>
        <div className="langSwitch__element" active={(props.lang === "en")?.toString()}
          onClick={() => { props.setLang("en") }}>
          🇺🇸
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    lang: state.lang
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLang: (width) => dispatch(setLang(width)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LangSwitch);
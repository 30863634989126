import React, { useEffect, useState } from 'react';
import './Sidebar.css';
import { getLatestArticle } from '../Index';
import { useNavigate } from 'react-router-dom';

function Sidebar(props) {
  const [latestBlogs, setLatestBlogs] = useState([]);
  const navigate = useNavigate();


  useEffect(() => {
    const list = getLatestArticle(5);
    setLatestBlogs(list)
  }, [])

  const selectArticle = (url) => {
    navigate(url);
  }

  return (
    <div className="sidebar">
      {props.extended &&
        <div className="sidebar__recent">
          {latestBlogs?.map((blog, index) =>
            <div className="sidebar__recent__box" key={index}
              onClick={() => { selectArticle(blog.url) }}>
              <div className="sidebar__recent__image">
                {blog.image}
              </div>
              <div className="sidebar__recent__title">
                <div><b>{blog?.title["en"]}</b></div>
                <div className="sidebar__recent__title__date">{blog?.date.toLocaleDateString()}</div>
              </div>
            </div>
          )}
        </div>}
    </div>
  )
}

export default Sidebar

import React from 'react';
import '../../../Article.css';
import { connect } from 'react-redux';
import InstaPost from '../../../General/InstaPost';
import { Helmet } from "react-helmet";

function B1(props) {

  return (
    <div className="article" >
      <Helmet>
        <title>
          {props.lang === "en" ? (
            <>Climbing in Damai/ Malaysia</>) :
            (<>マレーシア，ダマイで外岩クライミング</>)}
        </title>
        <meta name="description"
          content={props.lang === "en" ?
            "Small weekend trip to a crag which is really accessible from KL central." :
            "クアラルンプール郊外の外岩へ"} />
      </Helmet>
      <div className="article__header">
        <div className="article__header__main">
          <h1>
            {props.lang === "en" ? (
              <>Climbing in Damai/ Malaysia</>) :
              (<>マレーシア，ダマイで外岩クライミング</>)}
          </h1>
        </div>
        <div className="article__header__sub">
          {props.lang === "en" ? (
            <>Small weekend trip to a crag which is really accessible from KL central.</>) :
            (<>クアラルンプール郊外の外岩へ</>)}
        </div>
        <div className="article__header__date">
          PUBLISHED JULY 6, 2023
        </div>
      </div>
      <div className="article__body">
        <div className="article__body__intro">
          {props.lang === "en" ? (
            <>In the second weekend of my Malaysia trip, I went to Damai with friends I met at Camp5.
              By the way, this gym is pretty good so, you should check it out if you are staying in Malaysia.</>) :
            (<>マレーシア滞在の2週目，Camp5で出会った方々とダマイに行くことにした．
              Camp5というのはクアラルンプールにあるジムで，アクセスがよく，マレーシア滞在の際は是非伺ってみて欲しい．</>)}

        </div>
        <div className="article__body__paragraph" name="p1">
          <h2>Damai</h2>
          <p>
            {props.lang === "en" ? (
              <>This crag is beautiful.
                When I found the 5b under the tree, I was fully amazied by the beauty and power of the nature.<br />
                There are roughly 30 routes with a few sports multipitch.
                There are not much variation in grades there. Most of them are between 5b - 6c.
                However, because of the high temparature and humidity, you might feel harder than it says.
                In fact, with my typical Japanese sweaty hands, I often felt my hands are slipping off and chalk didn't help much.<br />
                Check the routes from the link below.<br />
                <a href="https://www.thecrag.com/en/climbing/malaysia/batu-caves/area/13950871" target="_blank">https://www.thecrag.com/en/climbing/malaysia/batu-caves/area/13950871</a></>) :
              (<>どこか神秘的で，植生のせいか沖縄のよう．
                特にこの5bは木の根に覆われ見ていて癒される．<br />
                ここには約30本ルートがあり，マルチも何本かある．
                グレードとしてはあまり種類がなく，ほとんどが5bから6cの間．
                気温と湿度が高いので，私の湿り手では少し難し感じたものもあった．<br />
                ルートに関して以下のリンクから調べて欲しい．<br />
                <a href="https://www.thecrag.com/en/climbing/malaysia/batu-caves/area/13950871" target="_blank">https://www.thecrag.com/en/climbing/malaysia/batu-caves/area/13950871</a></>)}
          </p>
          <InstaPost url="https://www.instagram.com/p/CtWX0TVS3RE/" />
        </div>
        <div className="article__body__paragraph" name="p2">
          <h2>
            {props.lang === "en" ? (
              <>How is the routes?</>) :
              (<>ルート感</>)}
          </h2>
          <p>
            {props.lang === "en" ? (
              <>I enjoyed the routes there. Most of the routes are clean and tall, and the bolts are fairly new.
                Lighning was a good long 6b for warm up.
                Also, I liked Dry season and Mono.
                But I found some routes were bolted not nicely.
                So, if you are new to climbing, you might want to check if the route is bolted ok before you climb.</>) :
              (<>どのルートも高さがあり，比較的綺麗で好印象．ボルトも新そうで，怖さはなかった．
                Lighningは長い6bでウォーミングアップにちょうどいい．
                Dry seasonとMonoは6cでまるで両極端のようなルートだが，どちらも面白かった．
                ただ，幾つかのルートはボルトの打ち方が少し変で，登りずらさや危険さを感じるものもあった．
                クライミングを始めたばかりの人は現地の人に，ボルトは大丈夫なのか確認してから登った方がいいかもしれない．
              </>)}

          </p>
        </div>
        <div className="article__body__paragraph" name="p3">
          <h2>
            {props.lang === "en" ? (
              <>Accessibility</>) :
              (<>アクセス</>)}
          </h2>
          <p>
            {props.lang === "en" ? (
              <>This crag is well-accessible from the central city. It's only about 30 min by car.
                Even if you decided to go there by grab, it wouldn't be expensive.
                This crag is in the private property. That's been said you need to pay for the entrance but it's only 5RM.
                They also rent gears, even shoes there.</>) :
              (<>クアラルンプール中心部からのアクセスはすごく良く，車で30分程度である．
                もしグラブを使って行ってもそこまで高額にはならない
                この場所は個人の土地にあり，入場料を払う必要があるがRM5と非常に安い．</>)}
          </p>
          <InstaPost url="https://www.instagram.com/p/CtWZYz9SIjC/" />
        </div>
        <div className="article__body__paragraph" name="p4">
          <h2>
            {props.lang === "en" ? (
              <>Tips</>) :
              (<>知っておくべき事</>)}
          </h2>
          <p>
            {props.lang === "en" ? (
              <><b>- Mosquito repellent:</b> there are so many mosquitos. I gave up at the end but it'll help.</>) :
              (<><b>- 虫除け:</b> ここにはたくさんの蚊がいるので，あると便利.</>)}
          </p>
          <p>
            {props.lang === "en" ? (
              <><b>- Be prepared for the heat:</b> Bring a hat, sunscreen, and enough WATER. Also get 100 plus.</>) :
              (<><b>- 暑さ対策:</b> 帽子，日焼け止め，水は多めに．100プラスも忘れずに．</>)}
          </p>
          <p>
            {props.lang === "en" ? (
              <><b>- Helmet:</b> This place requires you to wear a helmet when you climb and belay. If you don't own one you can rent from them.</>) :
              (<><b>- ヘルメット:</b> ここでは登る時，ビレイ時にヘルメットの着用が義務である．必要な方はその場でレンタルもできる．</>)}
          </p>
        </div>
        <div className="article__body__paragraph" name="p5">
          <h2>
            {props.lang === "en" ? (
              <>Conclusion</>) :
              (<>結論</>)}
          </h2>
          <p>
            {props.lang === "en" ? (
              <>Overall, it is a good crag if you stay in KL.
                Especially, for those who just started climb or wants to try climbing, this place would be a great choice.
                But for those who wants to climb harder stuffs, I definietly recommend that you go to other crags nearby such as Nyamuk.</>) :
              (<>クアラルンプールにいるのであれば検討する価値あり．
                特に，クライミングを始めたばかりの人や，挑戦してみたい人には素晴らしい場所だと思う．
                ただ，強度の強い課題が欲しい方は周辺に幾つも場所があるので，例えばNyamukに行くと良いかと思う．</>)}
          </p>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang
  };
};

export default connect(mapStateToProps)(B1);
import React from 'react';
import './A1.css';
import '../../../Article.css';
import { CopyBlock, dracula } from "react-code-blocks";
import Space from '../../../General/Space';
import navbar from '../A1/Image/navbar.jpg';

function A1() {
  return (
    <div className="article">
      <div className="article__header">
        <div className="article__header__main">
          <h1>How to make website with React and Firebase</h1>
        </div>
        <div className="article__header__sub">
          Looking to have your own website and deploy them? Follow this guide to build your website.
        </div>
        <div className="article__header__date">
          PUBLISHED JUNE 15, 2023
        </div>
      </div>
      <div className="article__body">
        <div className="article__body__intro">
        </div>
        <div className="article__body__paragraph" name="p1">
          <h2>Create React App</h2>
          <p>
            Initializing a react app is easy, simply run this command in the directory.<br />
            It'll create a react app named "my-website". You can choose whatever name you want for this one.
          </p>
          <CopyBlock
            text={`npx create-react-app my-website`}
            showLineNumbers={false}
            codeBlock
            theme={dracula}
          />
          <Space />
          <p>
            You'll see a react app created in the directory.<br />
            Let's see how your app looks like.<br />
            To run react app, run this command
          </p>
          <CopyBlock
            text="npm start"
            showLineNumbers={false}
            codeBlock
            theme={dracula}
          />
          <Space />
          <p>
            It comes with unnecessary  files. So, let's just delete app.test.js, logo.svg, setupTest.js.<br />
            Once you delete the files, you'll see errors. To fix the error, open app.js and clean the code related to the files which you delete.<br />
            Finally, empty app.css and add following code to index.css.
          </p>
          <CopyBlock
            codeBlock
            text={`* { 
  margin: 0
}`}
            showLineNumbers={false}
            theme={dracula}
          />
        </div >
        <div className="article__body__paragraph" name="p2">
          <h2>Initialize Firebase</h2>
          <p>
            1. Go to <a href="https://console.firebase.google.com/" target="_blank"><b>Firebase Console</b></a>.<br />
            2. Click <b>+ Add project</b> and follow the prompts to create a project. You can name your project anything you want.<br />
            3. Clink <b>{`</>`}</b> Web button to add firebase to your web app. You can name your app anything you want.<br />
            4. Follow the instruction and install firebase.
            5. Create firebase.js under /src and copy/paste the config which looks like something like this. You can find it in project setting.
          </p>
          <CopyBlock
            codeBlock
            text={`import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "key",
  authDomain: "domain",
  projectId: "id",
  storageBucket: "bucket",
  messagingSenderId: "id",
  appId: "id"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);`}
            showLineNumbers={true}
            language="jsx"
            theme={dracula}
          />
        </div>
        <div className="article__body__paragraph" name="p3">
          <h2>Organize Your Folder</h2>
          <p>
            Yes, you can simply start adding files under /src but I recommend that you create "Component" folder under /src to keep files organized for the future maintainance.<br />
            Once done, add div and set className="app__box". Now your App.js looks like this.
          </p>
          <CopyBlock
            codeBlock
            text={`import React from 'react';
import './App.css'; 

function App() {
  return (
    <div className='app'>
      <div className='app__box'>
      </div>
    </div>
  )
}
export default App`}
            language="jsx"
            showLineNumbers={true}
            theme={dracula}
          />
          <Space />
          <p>
            Let's style App.js. Open App.css and add following code
          </p>
          <CopyBlock
            codeBlock
            text={`.app{
  height: 100%;
  width: 100%;
}
            
.app__box {
  width: calc(100% - 128px);
  height: calc(100% - 128px);
  margin: 64px;
  border: 1px solid black;
  display: flex;
}`}
            language="css"
            showLineNumbers={true}
            theme={dracula}
          />
        </div>
        <div className="article__body__paragraph" name="p4">
          <h2>Navigation Bar</h2>
          <p>
            My website can be separated to left side and right side, and left side contains name, title, and navigationbar.<br />
          </p>
          <img className="article__body__image" src={navbar} />
          <p>
            Add div to separate the componet virtically.<br />
            Then create Navbar.js/css, Home.js/css, Info.js/css, Project.js/css under /src/Component.<br />
            You may have noticed that when you select on navigation bar, it doesn't reload the entire page but part of the componet is rerendered.<br />
            There are several ways to implement this but let's use <a href="https://www.npmjs.com/package/react-router-dom" target="_blank">react router</a> for practice. First of all, install react-router-dom
          </p>
          <CopyBlock
            codeBlock
            text={`npm i react-router-dom`}
            showLineNumbers={false}
            theme={dracula}
          />
          <p>
            The use of react router is simple. It switchs the component depends on the url.<br />
            In the following case, it shows Home by default, and when the url is /info, it shows Info.
          </p>
          <CopyBlock
            codeBlock
            text={`import React from 'react';
import './App.css'; 
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Home from './Home';
import Info from './Info';
import Project from './Project';
import Navbar from './Navbar';

function App() {
  return (
    <div className='app'>
      <div className='app__box'>
        <div className='app__box__left'>
          <div className='app__box__left__header'>
            <div className='app__box__left__header__name'>
              Your Name
            </div>
            <div className='app__box__left__header__title'>
              Title
            </div>
          </div>
          <div className="app__left__navbar">
            <Navbar />
          </div>
        </div>
        <div className='app__box__right'>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/info" element={<Info />} />
              <Route path="/project" element={<Project />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </BrowserRouter>
        </div>
      </div>
    </div>
  )
}
export default App`}
            language="jsx"
            showLineNumbers={true}
            theme={dracula}
          />
          <p>
            Also, update App.css
          </p>
          <CopyBlock
            codeBlock
            text={`.app{
  height: 100%;
  width: 100%;
}
            
.app__box {
  width: calc(100% - 128px);
  height: calc(100% - 128px);
  margin: 64px;
  border: 1px solid black;
  display: flex;
}

.app__left {
  width: 40%;
  height: 100%;
}

.app__left__header {
  padding: 30px;
}

.app__left__header__name {
  font-size: 40px;
}

.app__right {
  width: 60%;
  height: 100%;
}`}

            language="css"
            showLineNumbers={true}
            theme={dracula}
          />
          <p>
            Now open Navbar.js then copy and paste the following code. For next time, type rfce. It'll automatically generate skeleton component for you.
          </p>
          <CopyBlock
            codeBlock
            text={`import React from 'react';
import './Navbar.css';
import { useNavigate } from "react-router-dom";

function Navbar(props) {
  const navigate = useNavigate();
  return (
    <div className="navbar">
      <div className="navbar__element" onClick={() => { navigate('/') }}>
        <b>Home</b>
      </div>
      <div className="navbar__element" onClick={() => { navigate('/info') }}>
        <b>Info</b>
      </div>
      <div className="navbar__element" onClick={() => { navigate('/project') }}>
        <b>Project</b>
      </div>
    </div>
  )
}

export default Navbar`}
            language="jsx"
            showLineNumbers={true}
            theme={dracula}
          />
          <p>
            Then style the componet. In this tutorial, I don't go over the content of each page. <br />
            You can find complete code <a href="https://github.com" target="_blank">here</a>
          </p>
        </div>
        <div className="article__body__paragraph" name="p5">
          <h2>Host Your Website on Firebase</h2>
          <p>
            1. Log into Firebase using your Google account by running the following command:
          </p>
          <CopyBlock
            codeBlock
            text={`firebase login`}
            showLineNumbers={false}
            theme={dracula}
          />
          <p>
            2. Update to the latest CLI version
          </p>
          <CopyBlock
            codeBlock
            text={`npm install -g firebase-tools`}
            showLineNumbers={false}
            theme={dracula}
          />
          <p>
            3. Initialize a Firebase project (Here all you need to select is hosting in this tutorial but select more as you need.)
          </p>
          <CopyBlock
            codeBlock
            text={`firebase init`}
            showLineNumbers={false}
            theme={dracula}
          />
          <p>
            4. deploy
          </p>
          <CopyBlock
            codeBlock
            text={`npm run build`}
            showLineNumbers={false}
            theme={dracula}
          />
          <Space />
          <CopyBlock
            codeBlock
            text={`firebase deploy`}
            showLineNumbers={false}
            theme={dracula}
          />
        </div>
        <div className="article__body__paragraph" name="p6">
          <h2>Conclusion</h2>
          <p>
            React and Firebase are powerful tools to implement web application. It allows us to develop very quickly. <br />
            Thank you for reading this tutorial. You can find complete code <a href="https://github.com" target="_blank">here</a>
          </p>
        </div>
      </div >
    </div >
  )
}

export default A1
import React, { useState } from 'react';
import './Main.css';
import { Routes, Route } from 'react-router-dom';
import Header from './Header';
import Home from './Home';
import Blog from './Blog';
import Tech from './Tech';

function Main() {
  const [extended, setExtended] = useState(false);
  return (
    <div className="main">
      <Header extended={extended} setExtended={setExtended} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/general/*" element={< Blog extended={extended} />} />
        <Route path="/tech/*" element={<Tech extended={extended} />} />
      </Routes>
    </div>
  )
}

export default Main

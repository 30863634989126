import { SET_LANG, RESET_LANG, SET_WIDTH, RESET_WIDTH } from "./actiontype";

export const setLang = (lang) => {
  return {
    type: SET_LANG,
    payload: {
      lang: lang
    }
  }
}

export const resetLang = () => {
  return {
    tyep: RESET_LANG,
    payload: {
      lang: "en"
    }
  }
}

export const setWidth = (width) => {
  return {
    type: SET_WIDTH,
    payload: {
      width: width
    }
  }
}

export const resetWidth = () => {
  return {
    tyep: RESET_WIDTH,
    payload: {
      width: 500
    }
  }
}